export const API_VERSION = "v1.0";
let HOST_URL = window.location.host
let backend_url = ''
if (HOST_URL == 'www.qfxcinemas.com') {
  backend_url = 'https://web-api.qfxcinemas.com'
}
else if (HOST_URL == 'qfxcinemas.com') {
  backend_url =  'https://web-api.qfxcinemas.com'
}
else {
  backend_url = 'localhost'
}
export const BASEURL_BRIJ = backend_url

//const HOST_URL = `xanadu-mycinema.brij.tech`;
//const LIVEBASEURL = `https://xanadu-api.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;
export const BASEURL = backend_url;
//  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const SOCKETURL = `https://socket-api.qfxcinemas.com`;
export const IMG_BASEURL = `https://d346azgjfhsciq.cloudfront.net`;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const QFX_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjBmZDc1OWM2LTczMTYtNDdlZi1iZmYyLTg3ZWYwNTYxYWUxMCIsImlhdCI6MTY3MjEzNzgwOX0.niE2hcIACf-Fu_glcbh8Vtm5f83WUjygGG9GbJTw4-o'
export const TOKEN = QFX_TOKEN;

export const PUBLICKEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArtBdaXwlxWneKMsJnbxX
jrpAAulWJHR2x4wrARObKOhhWphFAt6j2AalxYg1Lh1CSfwFJl5GOASUgUyJ+Tgb
zCqhecjr2Nf/1jfgb4DC560lEM0pVHIG4HUf4ukvbppTLRJ1t/GXfhEWncgc91Fk
p6hx03erObYMYblqHZQC7jol/flSNoijw9UBCMCzSpoYjePuMi9oRz3TCl6xDuJD
VPt5gykvmHM6OTJkuWvrJojpp1gbvgKV1jnYSCsMLp6PLbKrZ9FivnX4+wRR5f/U
xxettS/lp+SP0Zcx4oz1xFhNo+SuyMlsQMg2WON0EDXdNTY8kE0tsFMkNIL5XsAk
+wIDAQAB
-----END PUBLIC KEY-----`;
